import ReCAPTCHA from "react-google-recaptcha";
import React, {useRef, useState} from "react";
import {toast} from "react-toastify";
import axios from "axios";

export default function Recaptcha(props) {
    const captchaRef = useRef(null)
    const [, setCaptchaResult] = useState(false)
    const isReCaptchaActive = process.env.REACT_APP_RECAPTCHA_IS_ACTIVE

    const notify = () => {
        captchaRef.current.reset();
        toast.error("Captcha unsuccessful!");
    }

    const recaptchaExpired = () => {
        setCaptchaResult(false)
        props.handleRecaptcha(false)
        captchaRef.current.reset();
    }

    const recaptchaSubmit=() => {
        const token = captchaRef.current.getValue();
        if(isReCaptchaActive === 'false'){
            setCaptchaResult(true)
            props.handleRecaptcha(true)
        }else if(token){
            let url = process.env.REACT_APP_API_BASE_URL+"/recaptcha/"+token
            axios.post(
                url,'',{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            ).then((res) => {
                if(res.status === 200 && res.data){
                    setCaptchaResult(true)
                    props.handleRecaptcha(true)

                }else {
                    captchaRef.current.reset();
                }
            }).catch((error) => notify())
        }else {
            notify()
        }
    }

    return (
        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef}
                   onChange={recaptchaSubmit} onExpired={recaptchaExpired}/>
    )
}