import React from 'react'


const BackButton = (props) => {

  const handleBack = () => {
    props.showLanding(true)
    props.showBackBTN(false)
  }

  return (
    <div className="flex justify-center flex-wrap -mx-3 mb-6 mt-2">
      <div className="flex justify-center mx-wid-600 ml-6 mr-6 w-full sm:w-2/3">
        <button onClick={() => handleBack()}
          className="w-1/2 bg-component hover:bg-component text-white font-bold py-2 px-4 rounded"
          type="submit">Back
        </button>
      </div>
    </div>
  )
}

export default BackButton