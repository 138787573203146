import { Divider } from 'antd'
import React, { useState } from 'react'

const CollapseInfo = (props) => {

    const [expand, setExpand] = useState(false)

    const toggle = () => {
        setExpand(!expand)
    }

    return (
        <div>
            <div className='flex justify-between'>
                <h3 className="text-md form-title bold text-gray-700 mb-1">{props.label}</h3>
                <div className='expand ml-4' >
                    <div className='bg-white shadow rounded expand-button-container float-right'>
                        <button className='text-2xl expand-button' onClick={toggle}>+</button>
                    </div>
                </div>
            </div>
            {expand && (<div className='toggle mb-4'>{props.children}</div>)}
        </div>
    )
}

export default CollapseInfo