import React, { useState } from 'react';
import { Formik, Form, ErrorMessage, Field } from "formik"
import { toast, ToastContainer } from "react-toastify";
import * as Yup from 'yup'
import 'react-toastify/dist/ReactToastify.css';
import Recaptcha from "./recaptcha";


const CardTransactionIdSearchForm = (props) => {

  const [captchaResult, setCaptchaResult] = useState(props.recaptchData)

  const notify = () => toast.error("Captcha unchecked!");
  const updateRecaptchaState = (visibility) => {
    setCaptchaResult(visibility)
    props.handleRecaptcha(visibility)
  }

  return (
    <div>
      <Formik
        initialValues={{
          cardTransactionId: '',
        }}

        validationSchema={Yup.object({
          cardTransactionId: Yup.string()
            .min(5, 'Invalid card transaction statement').required('Required')
        })}

        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          if (captchaResult) {
            props.handlePayloadChange({
              // cardStatementTransactionId: values.cardTransactionId
              cardStatementTransactionId: values.cardTransactionId.toLowerCase()
            })
          } else {
            notify();
          }
        }}
      >
        {formik => (
          <div className="bg-body flex justify-center flex-wrap w-full mb-2">
            <div className="flex items-center flex-col mx-wid-600 bg-white shadow rounded px-8 pt-6 pb-8 mb-4 mt-4 ml-6 mr-6 w-full sm:w-2/3 rm-px">

              <div className="mt-2 px-8">
                <h2 className="text-lg form-title bold text-gray-700">Tapped my phone using my mobile wallet</h2>
                <p>Obtain your receipt by sourcing your Transaction ID within your online banking account.</p>
                <h3 className="text-md form-title bold text-gray-700 mb-1">What is my Transaction ID?</h3>
                <p>Your Transaction ID will be listed in the description field of your transaction from shopping at The Runner in your online banking account. 
                  An example of the Transaction ID is: <span className='bold'>Marvel* xdpo976</span>. Simply enter the code following the * to search. For example <span className='bold'>"xdpo976"</span>.</p>
              </div>
              <Form className="px-8 pt-6 mb-2 w-full ml-6 mr-6 ">
                <div className="mb-4">

                  <h3 className="text-sm form-title bold mb-1">Transaction ID</h3>

                  <Field data-tip data-for="cardTransactionId" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="cardTransactionId"
                    name="cardTransactionId"
                    type="cardTransactionId"
                  />

                  <ErrorMessage component="div" className="text-red-600" name="cardTransactionId" />

                  <div className="h-110 py-4 min-wid-247">
                    <div className="resize-recaptcha" hidden={captchaResult}>
                      <Recaptcha handleRecaptcha={updateRecaptchaState} />
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center w-full">
                  <button className="w-1/3 bg-component hover:bg-component text-white font-bold py-2 px-4 rounded" type="submit">Search</button>
                </div>

              </Form>
              <ToastContainer position={toast.POSITION.BOTTOM_CENTER} closeOnClick={false} hideProgressBar />
            </div>
          </div>
        )}
      </Formik>
    </div>
  )
}

export default CardTransactionIdSearchForm;