import React, {useEffect, useState} from "react";
import moment from 'moment-timezone';

const OrderReceipt = (props) => {
  const [refundOrderAmount, setRefundOrderAmount] = useState(0)

  const backBTN = () => {
    props.setShowReceipt(false)
  }
  useEffect(() => {
    if(!props.order.products){
      return;
    }
    let refundAmount = 0;
    for (const product of props.order.products) {
      if(product.refundQuantity && product.quantity >= product.refundQuantity){
       refundAmount += product.basePrice * product.refundQuantity;
      }
    }
    setRefundOrderAmount(refundAmount)
  },[props.order.orderId])

  const renderProductDetails = (lineStyle, colorStyle, product) => {

    return(
        <div className="ml-5">
          <p className="mb-1">Price: ${parseFloat(product.basePrice).toFixed(2)}</p>
          <p className={lineStyle +" "+colorStyle+" mb-1"}>Quantity: {product.quantity}</p>
          <p className={lineStyle +" "+colorStyle+" mb-1"}>Product Total: ${parseFloat(product.basePrice * product.quantity).toFixed(2)}</p>
        </div>
    )
  }
  const renderProducts = () => {
    const stockThrough = "line-through";
    const redColor = "text-rose-700";
    if (!props.order.shoppingTripId) {
      return;
    }
    if (!props.order.transactionalData){
      return "";
    }
    return props.order.products.map((product, index) => {

      let itemDetails = ""
      if(product.refundQuantity === product.quantity){
        itemDetails = renderProductDetails(stockThrough,redColor,product)
      }else if(product.refundQuantity > 0){
        itemDetails = renderProductDetails(stockThrough,redColor,product)
      }else{
        itemDetails = renderProductDetails("","",product)
      }

      return (
        <div className="leading-4" key={index}>
          <p className="mb-1 bold">{index + 1} - {product.title} </p>
          {itemDetails}
          {product.refundQuantity > 0 && (
            <div className="ml-5 mt-3">
              {/*<p className="mb-1">Price: {parseFloat(product.basePrice).toFixed(2)}</p>*/}
              <p className="mb-1">Refund Quantity: - {product.refundQuantity}</p>
              <p className="mb-1">Refund Total: - ${parseFloat(product.basePrice * product.refundQuantity).toFixed(2)}</p>
            </div>
          )}

          <br />
        </div>
      )
    })
  }
  if (!props.order){
    return "";
  }
  return (
    <div className="flex justify-center flex-wrap -mx-3 mb-2 rm-mx ad-px">
      <div className="bg-white shadow rounded ">
        <div className="mt-3 px-6 w-120 ">
          <div className="center sm:w-2/3 receipt-width leading-4">
            <div className="center bg-black logo w-full mt-3 mb-3 flex justify-between">
              <img className="logo-item" src="/The-Runner_A_RGB_REV.png" alt="The Runner Logo" />
            </div>

            <div className="text-center mt-5">
              <h3 className="mb-1">740 Bourke St,</h3>
              <h3 className="mb-1">Docklands VIC 3008</h3>
              <h3 className="mb-1"><a href="mailto:reception.msl@afl.com.au" >reception.msl@afl.com.au</a></h3>
              <h3 className="mb-1">ABN: 92 003 435 345</h3>
            </div>

            <div className='text-primary-brand-color text-2xl mt-2 flex justify-center flex-wrap bold '>
              <h2>Tax Invoice</h2>
            </div>
            <div className="text-center border-b">
              <h3 className="mb-1">{moment(props.order.shoppingTripEndDateTime).tz('Australia/Melbourne').format("MMMM Do YYYY, h:mm:ss a")}</h3>
            </div>

            <div className="mt-5">
              <p className="mb-2"><span className="bold text-gray-700">Receipt No: </span>{props.order.orderId}</p>
              <p className="mb-2"><span className="bold text-gray-700">Reconciliation ID: </span>{props.order.transactionalData.reconciliationId}</p>
              <p className="mb-2"><span className="bold text-gray-700">Store name: </span>{props.order.storeName}</p>
              <p className="mb-2"><span className="bold text-gray-700">Card Tail: </span>############{props.order.transactionalData.cardTail} </p>
              <p className="mb-2"><span className="bold text-gray-700">Card Expiry: </span>{props.order.transactionalData.cardExpirationMonth}/{props.order.transactionalData.cardExpirationYear}</p>
              <p className="mb-3 mt-3"><span className="bold text-gray-700">Product details: </span></p>
            </div>

            <div className="ml-4">
              {renderProducts()}
            </div>
            { refundOrderAmount > 0 &&
                (
                    <>
                    <p className="mb-2"><span className="bold text-gray-700">Order Amount: </span>${parseFloat( props.order.productTotal ).toFixed(2)}</p>
                    <p className="mb-2"><span className="bold text-gray-700">Refunded Amount: </span>${parseFloat( refundOrderAmount).toFixed(2)}</p>
                    </>
                )
            }
            <p className="mb-2"><span className="bold text-gray-700">Order Total: </span>${parseFloat(props.order.productTotal - refundOrderAmount).toFixed(2)}</p>
            <p className="mb-2"><span className="bold text-gray-700">Amount Paid: </span>${parseFloat(props.order.amountPaid - refundOrderAmount).toFixed(2)}</p>
            {/* <p className="mb-2"><span className="bold text-gray-700">Balance Due: </span>${parseFloat(props.order.productTotal - props.order.amountPaid).toFixed(2)}</p> */}
            <p className="mb-2"><span className="bold text-gray-700">GST Total: </span>${parseFloat((props.order.productTotal - refundOrderAmount) * (10 / 100)).toFixed(2)}</p>
          </div>
          <div className="mt-6 flex justify-center">
            <img className="logo-item receipt-width w-2/3 py-2 px-4 rm-px" src="/MS_Long.png" alt="The Runner Logo" />
          </div>
        </div>

        <hr />
        <div className="flex items-center justify-center my-4">
          <button onClick={() => backBTN()}
            className="w-2/3 bg-component hover:bg-component text-white font-bold py-2 px-4 rounded"
            type="submit">Back
          </button>
        </div>
      </div>

    </div>
  )
}

export default OrderReceipt