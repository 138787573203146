import 'antd/dist/antd.min.css';
import {
  Routes,
  Route
} from "react-router-dom";
import { SearchOrders } from './components/orders';
import { Navbar, Header, Footer } from './components/core/layout'

function App() {
    const isReCaptchaActive = process.env.REACT_APP_RECAPTCHA_IS_ACTIVE

    if(isReCaptchaActive === 'false'){
        console.log("recaptcha disabled")
    }

  return (
    <div className="bg-body flex flex-col h-screen base-container">
      <Header />
      <div className='bg-body flex flex-col h-screen'>
        {/* <Navbar /> */}
        <Routes >
          <Route path="/" element={<SearchOrders />} />
          {/*<Route path="/landing" element={<StoreEntrance />} />*/}
          <Route path="/search" element={<SearchOrders />} />
          {/*<Route path="receipt-preview/:orderTripId/:ordersScopeToken" element={<ReceiptPreview />} />*/}
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;