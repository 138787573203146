import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white sticky top-[100vh] bg-body mt-6">
      <div className="flex justify-center bg-white soft-shadow">
        <div className="">
          <img className="logo-item marvel" src="/MS_Logo.png" alt="Marvel Stadium Logo" />
        </div>
        <div className="">
          <img className="logo-item" src="/Delaware_Logo.png" alt="Logo-delaware" />
        </div>
      </div>
    </footer>
  )
}

export default Footer;
