import React, { useState } from 'react';
import { Formik, Form, ErrorMessage, Field } from "formik"
import * as Yup from 'yup'
import TooltipLabel from "../info/tooltipLabel";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Recaptcha from "./recaptcha";



const EmailSearchForm = (props) => {
  const [captchaResult, setCaptchaResult] = useState(props.recaptchData)

  const notify = () => toast.error("Captcha unchecked!");
  const updateReCaptchaState = (visibility) => {
    setCaptchaResult(visibility)
    props.handleRecaptcha(visibility)
  }

  return (
    <div>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string().email('Invalid email address').required('Required')
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          if (captchaResult) {
            props.handlePayloadChange({
              email: values.email
            })
          } else {
            notify()
          }
        }}
      >
        {formik => (
          <div className="bg-body flex justify-center flex-wrap -mx-3 mb-2 mt-2">
            <div className="flex items-center flex-col mx-wid-600 bg-white shadow rounded px-8 pt-6 pb-8 mb-4 mt-4 ml-6 mr-6 w-full sm:w-2/3 rm-px">

              <div className="mt-2 px-8">
                <h2 className="text-lg form-title bold text-gray-700">Returning customer?</h2>
                <p>If you have registered your email with us before by sourcing a receipt, simply re-enter your email address below to receive your receipt.</p>
              </div>
              <Form className="mx-wid-600 px-8 pt-6 mb-4 w-full">
                <div className="mb-4">
                <h3 className="text-sm form-title bold mb-1">Email Address</h3>

                  <Field data-tip data-for="email" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    name="email"
                    type="email"
                  />

                  <ErrorMessage component="div" className="text-red-600" name="email" />

                  <div className="h-110 py-4 min-wid-247">
                    <div className="resize-recaptcha" hidden={captchaResult}>
                      <Recaptcha handleRecaptcha={updateReCaptchaState} />
                    </div>
                  </div>

                </div>
                <div className="flex items-center justify-center w-full">
                  <button className="w-1/3 bg-component hover:bg-component text-white font-bold py-2 px-4 rounded" type="submit">Search</button>
                </div>
              </Form>
              <ToastContainer position={toast.POSITION.BOTTOM_CENTER} closeOnClick={false} hideProgressBar />
            </div>
          </div>

        )}
      </Formik>
    </div>
  )
}

export default EmailSearchForm;