import React from 'react';
import { FaReceipt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { AnimatedTooltip } from '../info/animatedTooltip.tsx'
import moment from 'moment-timezone'

const OrderList = (props) => {

  const renderTableContent = () => {
    if (!props.orders) {
      return "";
    }
    const openReceiptPreview = (shoppingTripId) => {
      props.setShowReceipt(true)
      props.showOrderDetails(shoppingTripId)
    }

    return props.orders.map((order, index) => {
      return (
        <tr key={order.shoppingTripId}>
          <td className='border-b rounded border-color-brand p-4 text-center'>{order.receiptNumber}</td>
          <td className='border-b rounded border-color-brand p-4 text-center'>{moment(order.shoppingTripDate).tz('Australia/Melbourne').format("DD/MM/YY")}</td>
          <td className='border-b rounded border-color-brand p-4 text-center'>
            <div className='flex justify-center'>
            <AnimatedTooltip label="Click here to see your transaction receipt online.">
              <span className='float-left mt-1'>
                <i className='cursor-pointer' onClick={() => openReceiptPreview(order.shoppingTripId)}>
                  <FaReceipt />
                </i>
              </span>
            </AnimatedTooltip>

            <AnimatedTooltip label="Click here to email a receipt for this transaction to yourself.">
              <span className='float-left ml-2 mt-1'>
                <i className='cursor-pointer' onClick={() => props.showSendEmailReceipt(order.shoppingTripId)}>
                  <FaEnvelope />
                </i>
              </span>
            </AnimatedTooltip>
                          
            </div>
          </td>
        </tr>
      )
    })
  }

  return (
    <table className="mx-wid-600 bg-white shadow rounded table-auto border-collapse text-sm w-full sm:w-2/3 px-8 rm-mx mb-6">
      <thead>
        <tr className='border-b rounded border-color-brand font-medium p-4 pl-8 pb-3 mt-3 text-center' key={"head"}>
          <th className='border-b border-color-brand font-medium p-4 pl-8 pr-8 mt-4 table-head'><b>Receipt No.</b></th>
          <th className='border-b border-color-brand font-medium p-4 pl-8 pr-8 mt-4 table-head'><b>Transaction Date</b></th>
          <th className='border-b border-color-brand font-medium p-4 pl-8 pr-8 mt-4 table-head'><b>Receipt</b></th>
        </tr>
      </thead>
      <tbody>
        {renderTableContent()}
      </tbody>
    </table>
  )
}

export default OrderList;