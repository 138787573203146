import React, {useEffect} from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

const YearPickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <DatePicker
        showYearPicker
      {...field}
      {...props}
        monthItemNumber={9}
      // selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        setFieldValue(field.name, moment(val).format('YYYY'));
      }}
    />
  );
};


export default YearPickerField