import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import CollapseInfo from '../info/collapseInfo'

const Landing = (props) => {

  const handleSearchRadioChange = (searchSelected) => {
    if (!props.searchRadioChange) {
      return "";
    }
    // console.log(searchSelected)
    props.searchRadioChange(searchSelected)
    props.showLanding(false)
  }
  return (
    <>
      <div className="flex flex-col items-center -mx-3">

        <div className='bg-white shadow rounded mx-wid-900 px-8 pt-6 pb-3 mb-2 sm:w-2/3 ml-6 mr-6 mt-4 '>
          <h3 className="text-2xl form-title bold text-gray-700 mb-1">Let's help you find your receipt.</h3>
          <h3 className="text-2xl form-title bold text-gray-700 mb-6">How did you enter the store?</h3>
        </div>

        <div className='bg-white shadow rounded mx-wid-900 px-8 pt-6 pb-3 mb-2 sm:w-2/3 ml-6 mr-6 mt-4 rm-mt rm-px'>
          <div className="flex flex-col items-center justify-center px-8 ml-6 mr-6 mt-4">
            <div className="mb-6 flex justify-center ml-6 mr-6 w-full ">
              <button className="bg-component hover:bg-component text-white font-bold py-2 px-4 rounded w-full hard-shadow" type="submit" value="TransactionID" onClick={() => handleSearchRadioChange("TransactionID")}>Tapped my phone using my mobile wallet</button>
            </div>
            <div className="mb-6 flex justify-center ml-6 mr-6 w-full">
              <button className="bg-component hover:bg-component text-white font-bold py-2 px-4 rounded w-full hard-shadow" type="submit" value="Raw" onClick={() => handleSearchRadioChange("Raw")}>Tapped or inserted my physical card</button>
            </div>

            <p className="mt-4 mb-4"><span className="text-md form-title bold text-gray-700">Returning customer?</span> If you have registered your email with us before by sourcing a receipt, click the link below and simply re-enter your email address to receive your receipt.</p>
            <div className="mb-6 flex justify-center ml-6 mr-6 w-full">
              <button className="bg-component hover:bg-component text-white font-bold py-2 px-4 rounded w-full hard-shadow" type="submit" value="email" onClick={() => handleSearchRadioChange("email")}>Returning customer</button>
            </div>
          </div>
        </div>

        <div className='bg-white shadow rounded mx-wid-900 px-8 pt-6 pb-3 mb-6 sm:w-2/3 ml-6 mr-6 mt-4 '>
          <h2 className="text-2xl form-title bold text-gray-700 mb-3">More information</h2>
          <CollapseInfo className="mb-4" label='What transactions will appear in my online banking account after shopping at The Runner?'>
            {/* <h3 className="text-md form-title bold text-gray-700 mb-1">What transactions will appear in my online banking account after shopping at The Runner?</h3> */}
            <p className="mb-1">There will most likely be two initial charges that appear on your card, with the same Transaction ID* </p>
            <ul className='ml-6 mb-2'>
              <li type='circle'>The first will be the pre-authorisation amount</li>
              <li type='circle'>The second will be the balance of your purchase <em>(if over the initial pre-authorisation amount)</em></li>
            </ul>
            <p className="mb-2">Once the transaction settles, you will likely only see one transaction which will be the total of your purchase.</p>
            <p className="mb-2"><span className='bold'>Note:</span> if transaction amount is lower than the initial pre-authorisation amount, this amount will be reduced to transaction value when it settles.</p>
            <p className="mb-1"><span className='bold'>*</span>Your Transaction ID will be listed in the description field of your transaction from shopping at The Runner in your online banking account. An example of the Transaction ID is: <span className='bold'>Marvel* xdpo976</span></p>

          </CollapseInfo>
          <CollapseInfo className="mb-4" label='When will my receipt become available?'>
            {/* <h3 className="text-md form-title bold text-gray-700 mb-1">When will my receipt become available?</h3> */}
            <p>
              Once the balance of your purchase appears in your online banking account, you will be able to use the Transaction ID number to obtain your receipt.
              Please note, the full transaction may take up to 48 hours to settle in your bank account.
            </p>
          </CollapseInfo>
          <CollapseInfo className="mb-4" label='Having trouble sourcing your receipt?'>
            {/* <h3 className="text-md form-title bold text-gray-700 mb-1">Having trouble sourcing your receipt?</h3> */}
            <p className="mb-4">
              Please reach out to our team if you are having difficulty sourcing your receipt.
              Send us an email, include your contact details, and we will assist: <a href="mailto:reception.msl@afl.com.au" target="_blank" rel="noopener noreferrer">reception.msl@afl.com.au</a>.
            </p>
          </CollapseInfo>
          <p>Visit our website <a href="https://marvelstadium.com.au/therunner/" target="_blank" rel="noopener noreferrer">here</a> for more details about The Runner.</p>
        </div>

      </div>
    </>
  )
}

export default Landing