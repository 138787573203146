import React from 'react';


function Header() {
  // Import result is the URL of your image

  return (
    <div className='bg-header'>
      <div className="header-container hard-shadow bg-black flex justify-center flex-wrap w-full">
        <div className="logo w-full mt-3 mb-3 flex justify-between sm:w-2/3">
          <img className="logo-item runner" src="/The-Runner_B_RGB_REV.png" alt="The Runner Logo" />
        </div>
      </div>
      <div className='text-primary-brand-color text-3xl mt-5 rm-mb flex justify-center flex-wrap'>
        <h1 >Receipt Portal</h1>
      </div>
    </div>
  );
}

export default Header;