import React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import SearchForm from "./searchForm";
import EmailSearchForm from "./emailSearchForm";
import SearchTypeForm from './searchTypeForm';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import OrderList from './orderList';
import OrderDetailModal from './orderDetailModal'
import SendEmailReceiptModal from './sendEmailReceiptModal';
import CardTransactionIdSearchForm from "./cardTransactionIdSearchForm";
import SendEmailWithEmailAddressReceiptModal from "./sendEmailWithEmailAddressReceiptModal";
import OrderReceipt from "./orderReceipt";
import Landing from "./landing";
import BackButton from '../core/layout/backButton';


const SearchOrders = () => {
    const [payload, setPayload] = useState("");
    const [data, setData] = useState("");
    const [selectedSearch, setSelectedSearch] = useState("");
    const [orderDetailClickedOrderId, setOrderDetailClicked] = useState("")
    const [orderDetail, setOrderDetails] = useState("")
    const [showOrderDetailModal, setShowOrderDetailModal] = useState(false)
    const [showSendEmailReceiptModal, setShowSendEmailReceiptModal] = useState(false)
    const [showSendEmailReceiptWithEmailAddressModal, setShowSendEmailReceiptWithEmailAddressModal] = useState(false)
    const [error, setError] = useState("")
    const [loaded, setLoaded] = useState("")
    const [recaptchaVisibility, setRecaptchaVisibility] = useState(false)
    const [showReceipt, setShowReceipt] = useState(false)
    const [landing, setLanding] = useState(true)
    const [showbackBTN, setShowBackBTN] = useState(false)


    const receiptPortalApiBaseURL = process.env.REACT_APP_API_BASE_URL


    const processDataFetchError = (error) => {
        setError(error);
    };

    const processDataFetch = (data) => {
        setData(data);
    }

    const orderDetailClicked = (orderId) => {
        setOrderDetailClicked(orderId);
        setShowOrderDetailModal(true);
    }

    const sendEmailReceiptClicked = (orderId) => {
        setOrderDetailClicked(orderId);
        if (data.shopperEmail !== ""){
            setShowSendEmailReceiptWithEmailAddressModal(true);
        }else{
            setShowSendEmailReceiptModal(true);
        }
    }

    const searchRadioChange = (event) => {
        setSelectedSearch(event.target.value);
        console.log(event.target.value)
        setData("");
    }
    const searchRadioChangeV2 = (event) => {
        setSelectedSearch(event);
        setData("");
    }

    const handleShowLanding = () => {
        setLanding(true)
        setShowBackBTN(false)
    }

    useEffect(() => {
        setData("");
        if (payload === "") {
            return;
        }

        let searchPayload;

        if (payload.email) {
            searchPayload = JSON.stringify({
                emailSearch: payload
            });
        } else if (payload.cardStatementTransactionId){
            searchPayload = JSON.stringify({
                transactionId: payload
            });
        } else {
            searchPayload = JSON.stringify({
                rawSearch: payload
            });
        }

        toast.promise(axios.post(receiptPortalApiBaseURL + "/orders/search", searchPayload, {
            headers: {
                'Content-Type': 'application/json'
            }
        }),
            {
                pending: 'Loading...',
                error: {
                    render({ data }) {
                        return data.message
                    }
                }
            }
        )
            .then((response) => processDataFetch(response.data))
            .catch((error) => { processDataFetchError(error) })
            .finally(() => setLoaded(true));
    }, [payload]);


    useEffect(() => {
        if (orderDetailClickedOrderId === "") {
            return;
        }

        toast.promise(axios
            .get(receiptPortalApiBaseURL + "/orders/" + orderDetailClickedOrderId, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data.ordersScopeToken
                }
            }),
            {
                pending: 'Loading...',
                error: {
                    render({ data }) {
                        return data.message
                    }
                }
            })
            .then((response) => {
                setOrderDetails(response.data)
            })
            .catch((error) => processDataFetchError(error));
    }, [orderDetailClickedOrderId]);

    let searchContent
    if(selectedSearch==="email"){
         searchContent = <EmailSearchForm removeSelectSearch={setSelectedSearch} showLanding={handleShowLanding} handlePayloadChange={setPayload} handleRecaptcha={setRecaptchaVisibility} recaptchData={recaptchaVisibility}></EmailSearchForm>
    }
    if (selectedSearch === "Raw") {
        searchContent = <SearchForm removeSelectSearch={setSelectedSearch} showLanding={handleShowLanding} handlePayloadChange={setPayload} handleRecaptcha={setRecaptchaVisibility} recaptchData={recaptchaVisibility}></SearchForm>
    }
    if (selectedSearch === "TransactionID") {
            searchContent = <CardTransactionIdSearchForm removeSelectSearch={setSelectedSearch} showLanding={handleShowLanding} handlePayloadChange={setPayload} handleRecaptcha={setRecaptchaVisibility} recaptchData={recaptchaVisibility}></CardTransactionIdSearchForm>
        }


    return (
        <div className="">
            {showReceipt ? <OrderReceipt setShowReceipt={setShowReceipt} order={orderDetail} showOrderDetails={orderDetailClicked} /> :

            <>
            {/*<SearchTypeForm  searchRadioChange={searchRadioChange} selectedSearch={selectedSearch}></SearchTypeForm>*/}
            { landing ?
                <Landing showLanding={setLanding} searchRadioChange={searchRadioChangeV2} selectedSearch={selectedSearch}></Landing>:
                <div>
                    {searchContent}
                </div>
            }

            {/*<div>*/}
            {/*    {searchContent}*/}
            {/*</div>*/}

            <div className='flex justify-center center flex-wrap overflow-x-scroll -mx-3 mb-2'>
                {!landing && data.orders && (<OrderList ordersScopeToken={data.ordersScopeToken} orders={data.orders} showOrderDetails={orderDetailClicked} showSendEmailReceipt={sendEmailReceiptClicked} setShowReceipt={setShowReceipt} />)}
            </div>
            {!landing && data && !data.orders && (
                <div className='flex justify-center flex-wrap -mx-3'>
                    <div className="bg-white shadow rounded p-4 mb-2 w-full sm:w-1/2">
                        Receipt pending.
                    </div>
                </div>
            )}
            {/*{data && data.orders && (<OrderDetailModal showModal={showOrderDetailModal} order={orderDetail} setShowModal={setShowOrderDetailModal} />)}*/}
            {data && data.orders && 
            (<>
            <SendEmailReceiptModal showModal={showSendEmailReceiptModal} order={orderDetail} setShowModal={setShowSendEmailReceiptModal} orderScopingToken={data.ordersScopeToken} />
            
            </>)}
            {data && data.orders && (<SendEmailWithEmailAddressReceiptModal
                showSendEmailReceiptModal={showSendEmailReceiptModal}
                setshowSendEmailReceiptModal={setShowSendEmailReceiptModal}
                showModal={showSendEmailReceiptWithEmailAddressModal} shopperEmail={data.shopperEmail} order={orderDetail}
                setShowModal={setShowSendEmailReceiptWithEmailAddressModal} orderScopingToken={data.ordersScopeToken} />)}
            <ToastContainer closeOnClick={false} hideProgressBar />
            {!landing && <BackButton showLanding={setLanding} showbackBTN={setShowBackBTN} />}
        </>
            }
        </div>
    )
}

export default SearchOrders;