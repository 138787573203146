import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage, Field } from "formik"
import * as Yup from 'yup'
import DatePickerField from "./datePicker";
import { toast } from "react-toastify";
import Recaptcha from "./recaptcha";
import YearPicker from "./yearPicker";
import MonthPicker from "./monthPicker";
import MonthPickerField from "./monthPicker";
import YearPickerField from "./yearPicker";

const SearchForm = (props) => {
  const [captchaResult, setCaptchaResult] = useState(props.recaptchData)

  let cardYear = ''
  let cardMonth = ''
  const notify = () => toast.error("Captcha unchecked!");

  const updateReCaptchaState = (visibility) => {
    setCaptchaResult(visibility)
    props.handleRecaptcha(visibility)
  }
  const handleSetCardExpiryDate = (date) => {
    const dat = date.split("/")
    if (dat[0] !== '') {
      const month = dat[0].substring(0, 1)
      if (month === '0') {
        cardMonth = dat[0].substring(1)
      } else {
        cardMonth = dat[0]
      }
    }
    if (dat[1] !== '') cardYear = dat[1]
  }

  return (
    <div>
      <Formik
        initialValues={{
          cardTail: '',
          cardExpiryDate: '',
        }}
        validationSchema={Yup.object({
          cardExpiryDate: Yup.string().required('Required')
            .min(7, 'Must be exactly 2 digits for a Month and 4 digits for Year')
            .max(7, 'Must be exactly 2 digits for a Month and 4 digits for Year'),
          cardTail: Yup.string()
            .min(4, 'Must be exactly 4 digits')
            .max(4, 'Must be exactly 4 digits')
            .required('Required')
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          if (captchaResult) {
            handleSetCardExpiryDate(values.cardExpiryDate)
            props.handlePayloadChange({
              creditCardLastFourDigits: values.cardTail,
              creditCardExpiryYear: cardYear,
              creditCardExpiryMonth: cardMonth,
            })
          } else {
            notify()
          }
        }}
      >
        {formik => (
          <div className="bg-body flex justify-center flex-wrap -mx-3 mb-2">
            <div className="flex items-center flex-col mx-wid-600 bg-white shadow rounded px-8 pt-6 pb-8 mb-4 mt-4 ml-6 mr-6 w-full sm:w-2/3 rm-px">

              <div className="mt-2 px-8">
                <h2 className="text-lg form-title bold text-gray-700">Tapped or inserted my physical card</h2>
                <p>Obtain your receipt using your credit, debit or EFTPOS card details. Please ensure the details entered are specific to the card you entered with at The Runner.</p>
              </div>
              <Form className="mx-wid-600 px-8 pt-6 mb-4 w-full ml-6 mr-6">

                <div className="mb-4">
                  <h3 className="text-sm form-title bold mb-1">Card Expiry Date</h3>
                  <DatePickerField name="cardExpiryDate"
                    dateFormat="MM/yy"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" />
                  <ErrorMessage component="div" className="text-red-600" name="cardExpiryDate" />
                </div>


                <div className="mb-4">
                  <h3 className="text-sm form-title bold mb-1">Last 4 Digits of Card</h3>
                  <Field className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="cardTail"
                    name="cardTail"
                    type="cardTail"
                  />
                  <ErrorMessage component="div" className="text-red-600" name="cardTail" />
                </div>
                
                <div className="h-110 py-4 min-wid-247">
                  <div className="resize-recaptcha" hidden={captchaResult}>
                    <Recaptcha handleRecaptcha={updateReCaptchaState} />
                  </div>
                </div>

                <div className="flex items-center justify-center w-full">
                  <button className="w-1/3 bg-component hover:bg-component text-white font-bold py-2 px-4 rounded" type="submit">Search</button>
                </div>
              </Form>

            </div>
          </div>

        )}
      </Formik>
    </div>
  )
}

export default SearchForm;